import React, { useState } from "react";
import { Link } from "react-router-dom";
import style from "../constants/style";
import styled from "styled-components";
import Context from "../Context";
import pages from "../pages/";
import { Flex, Label } from "../components";
import themes from "../themes";
import { Collapse } from "reactstrap";
import * as R from "ramda";

import {
  FiChevronRight,
  FiChevronDown,
  FiChevronsLeft,
  FiMoreHorizontal,
} from "react-icons/fi";
const Hamburger = ({ setCollapsed, isCollapsed, isHome, bgColor }) => {
  const [mouseOver, setMouseOver] = useState(false);
  return (
    <HamburgerWrapper
      onClick={() => setCollapsed(!isCollapsed)}
      collapsed={isCollapsed}
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <img
        width={isCollapsed ? 50 : 150}
        src={
          isCollapsed
            ? "/images/HT_logo_squire_new2.png"
            : "/images/HT_new_logo.png"
        }
        alt="#1"
      />
      {isCollapsed || (
        <FiChevronsLeft className={mouseOver && "bounceX"} color="yellow" />
      )}
    </HamburgerWrapper>
  );
};

const SideBarItem = ({
  path,
  name,
  isSelected,
  handleClick,
  icon,
  isMinimize,
  isExpand,
  validChildren,
}) => {
  const [mouseOver, setMouseOver] = useState(false);
  return (
    <Flex width="100%" align="center">
      <Link to={path} onClick={handleClick} style={{ width: "100%" }}>
        <SideBarItemSc
          isSelected={isSelected}
          isMinimize={isMinimize}
          onMouseOver={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
        >
          <Label
            color={mouseOver ? themes.colors.primary : themes.colors.lightBlack}
            size={themes.fontSize.small}
            textAlign={isMinimize && "center"}
            weight={600}
          >
            {icon}
            {!isMinimize && "\u00A0\u00A0" + name}
          </Label>
          {!R.isEmpty(validChildren) &&
            !isMinimize &&
            (isExpand ? (
              <FiChevronDown
                color={
                  mouseOver ? themes.colors.primary : themes.colors.lightBlack
                }
              />
            ) : (
              <FiChevronRight color={themes.colors.lightBlack} />
            ))}
        </SideBarItemSc>
      </Link>
    </Flex>
  );
};
const MainSideBar = ({
  path,
  name,
  children,
  mother,
  child,
  setChild,
  setMother,
  Component,
  icon,
  isMinimize,
  setIsMinimize,
}) => {
  const [isExpand, setIsExpand] = useState(mother === path || false);
  const { profileState } = Context();
  const validChildren = children.filter(
    // (x) => !x.hideSideBar && (x.permissions || []).includes(profileState?.role)
    (x) =>
      !x.hideSideBar &&
      (profileState?.role === "dev" ||
        (profileState?.permissions || []).includes(x.path))
  );

  if (!Component && R.isEmpty(validChildren)) {
    return;
  }
  return (
    <>
      <SideBarItem
        path={Component ? path : "#"}
        name={name}
        icon={icon}
        isMinimize={isMinimize}
        isSelected={mother === path}
        isExpand={isExpand}
        validChildren={validChildren}
        // isSelected={!!validChildren.length}
        handleClick={() => {
          setIsExpand((x) => !x);
          setIsMinimize(false);
        }}
      />

      {validChildren.map(({ name, path }, i) => {
        return (
          <Collapse key={i} isOpen={isExpand && !isMinimize}>
            <Link to={path} style={SlideLinkCss(isExpand)}>
              <SubSideBarItemSc
                isLast={i + 1 === children.length}
                isSelected={path === child}
                key={i}
                onClick={() => setChild(path)}
              >
                <div style={SlideLinkCss(isExpand)}>
                  <Label
                    color={
                      path === child
                        ? themes.colors.white
                        : themes.colors.lightBlack
                    }
                    hoverColor={path !== child && themes.colors.primary}
                    weight={path === child ? 600 : 400}
                    size={themes.fontSize.small}
                  >
                    {path === child ? (
                      <FiMoreHorizontal className="mr-2" />
                    ) : (
                      "-"
                    )}{" "}
                    {name}
                  </Label>
                </div>
              </SubSideBarItemSc>{" "}
            </Link>
          </Collapse>
        );
      })}
    </>
  );
};
const findInitMotherChild = () => {
  let motherPath = "";
  let childPath = "";
  const path = window.location.pathname;
  pages.forEach((mother) => {
    if (mother.path === path) {
      motherPath = mother.path;
    }
    mother.children.forEach((child) => {
      if (child.path === path) {
        motherPath = mother.path;
        childPath = child.path;
      }
    });
  });
  return { motherPath, childPath };
};

const SideBar = ({ setTestAccess, profileState }) => {
  const [child, setChild] = useState(findInitMotherChild().childPath);
  const [mother, setMother] = useState(findInitMotherChild().motherPath);
  const [isMinimize, setIsMinimize] = useState(false);
  const [isHover, setIsHover] = useState(false);
  return (
    <SideBarbarHolder
      isMinimize={isMinimize && !isHover}
      // onMouseEnter={() => setIsHover(true)}
      // onMouseLeave={() => setIsHover(false)}
    >
      <SideBarContainer>
        <Holder>
          <Hamburger
            setCollapsed={setIsMinimize}
            isCollapsed={isMinimize}
            bgColor="white"
          />
          {
            <button
              className="bg-black px-4 py-2 text-white text-xs rounded-xl"
              onClick={() => setTestAccess("Y")}
            >
              Admin V.2 (ทดสอบ)
            </button>
          }
          <div style={{ padding: "0 10px 50px 10px" }}>
            {pages
              .filter((x) => !x.hideSideBar)
              .map((props, i) => (
                <MainSideBar
                  key={i}
                  {...props}
                  isMinimize={isMinimize && !isHover}
                  setIsMinimize={setIsMinimize}
                  mother={mother}
                  child={child}
                  setChild={setChild}
                  setMother={setMother}
                />
              ))}
          </div>
        </Holder>
      </SideBarContainer>
    </SideBarbarHolder>
  );
};
export default SideBar;

const SideBarContainer = styled.div`
  background-color: white;
  border-radius: 5px;
`;
const SideBarbarHolder = styled.div`
  overflow: scroll;
  position: relative;
  display: inline-block;
  text-align: center;
  font-weight: 900;
  color: black;
  font-size: 1.2em;
  width: 300px;
  height: 100%;
  transition: width 0.3s;
  ${({ isMinimize }) => isMinimize && `width: 70px;`}
`;
const Holder = styled.div`
  overflow: scroll;
  position: relative;
  display: inline-block;
  min-height: 100vh;
  width: 100%;
`;
const SideBarItemSc = styled.div`
  border-collapse: collapse;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 10px 15px;
  margin-top: 5px;
  text-align: left;

  ${({ isSelected }) =>
    isSelected
      ? `border-radius: 5px 5px 0px 0px;`
      : `border-radius: 5px 5px 5px 5px;`}

  ${({ isMinimize }) =>
    !isMinimize
      ? "justify-content: space-between"
      : " justify-content: center;"}
`;

const SubSideBarItemSc = styled(SideBarItemSc)`
  cursor: pointer;
  border-radius: 0.375rem;
  background: rgba(255, 255, 255, 1);
  margin: 10px 0;
  padding: 10px 25px;
  border: 0;
  :hover {
    color: ${themes.colors.primary} !important;
  }
  ${({ isSelected }) =>
    isSelected && `background: ${themes.colors.primary}; color: white;`}
`;
const SlideLinkCss = (isShow) => {
  return {
    display: "block",
    color: "black",
    height: isShow ? `auto` : `0`,
    opacity: isShow ? 1 : 0,
  };
};

const HamburgerWrapper = styled.div`
  display: flex;
  background: ${themes.colors.secondaryGradient};
  align-items: center;
  justify-content: ${(props) => (props.collapsed ? `center` : `space-between`)};
  border: 0;
  width: 100%;
  z-index: 99 !important;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 10px 15px;
`;
