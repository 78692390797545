import { useState, useEffect } from "react";
import { FaChartBar } from "react-icons/fa";
import { MdOutlineCollectionsBookmark } from "react-icons/md";
import { FiTarget, FiUsers } from "react-icons/fi";
import { AiOutlineSetting } from "react-icons/ai";
import { Avatar, AvatarImage, AvatarFallback, Separator } from "../ui";
import { Container } from "../base";
import { Text } from "../base";
import Context from "../../Context";
import { roundDateToDate } from "../../util";
import { Collapse } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import { BiCreditCardFront, BiDonateHeart } from "react-icons/bi";
import { RiStoreLine, RiCouponLine } from "react-icons/ri";
import { TbCashBanknote } from "react-icons/tb";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { FaMedal, FaDoorOpen } from "react-icons/fa";
import { paths } from "../../constants";
import { IoMdPaper } from "react-icons/io";

const mockPermission = ["/dashboard", "/users", "/users/new", "/orders"];

export const Sidebar = ({ profileState, minimize, setMinimize }) => {
  const location = useLocation();
  const { hash, pathname, search } = location;

  const navbarList = paths.reduce((acc, nav) => {
    if (nav.hide) return acc;
    if ((profileState.allowPaths || []).includes(nav.path)) {
      if (!nav.key) {
        acc[nav.name] = { name: nav.name, icon: nav.icon, path: nav.path };
      } else if (acc[nav.key]) {
        acc[nav.key].pages.push({ name: nav.name, path: nav.path });
      } else {
        acc[nav.key] = {
          name: nav.key,
          icon: nav.icon,
          pages: [{ name: nav.name, path: nav.path }],
        };
      }
    }

    return acc;
  }, []);

  return (
    <Container
      className={`flex flex-col max-w-[350px] justify-between h-fit duration-150 shadow-xl m-3 ${
        minimize && "p-2"
      }`}
    >
      <div>
        <div
          className={`flex items-start ${
            minimize ? "justify-center" : "justify-start"
          } overflow-hidden gap-6 p-3 duration-200 rounded-3xl cursor-pointer`}
          onClick={() => setMinimize(!minimize)}
        >
          <div className="relative">
            <Avatar className={minimize ? "w-8 h-8" : "w-16 h-16"}>
              <AvatarImage src={profileState.profileImage} />
              <AvatarFallback>
                {profileState?.firstName?.slice(0, 2)}
              </AvatarFallback>
            </Avatar>
            <div
              className={`w-4 h-4 border-2 z-10 bg-green-700 absolute bottom-0 right-0 rounded-full`}
            ></div>
          </div>

          <div className={`flex flex-col ${minimize && "hidden"} w-full`}>
            <Text className="text-base font-semibold truncate w-full max-w-[150px]">
              {profileState?.firstName + " " + profileState?.lastName}
            </Text>
            <Text className="text-sm font-semibold">
              {profileState?.userId}
            </Text>
            <Text
              className={`text-sm font-semibold text-gray-400
              `}
            >
              {profileState?.role}
            </Text>
          </div>
        </div>
        <Separator className={minimize ? "my-1" : "my-3"} />
        {Object.values(navbarList).map((nav, index) => {
          return (
            <SidebarItem
              icon={nav.icon}
              name={nav.name}
              path={nav.path}
              key={nav.name}
              pages={nav.pages || []}
              currentPath={pathname}
              isLastPage={index === Object.values(navbarList).length - 1}
              minimize={minimize}
              setMinimize={setMinimize}
            />
          );
        })}
      </div>
      <div className="mt-24">
        <SidebarItem
          minimize={minimize}
          icon={<AiOutlineSetting className="w-6 text-xl" />}
          name="ตั้งค่า"
          currentPath={pathname}
          path="/setting"
        />
        <div className="flex gap-3 px-4 py-3 cursor-pointer">
          <FaDoorOpen className="w-6 text-xl text-gray-500 hover:text-gray-950" />
          <Text
            className="text-gray-500 hover:text-gray-950"
            onClick={() => {
              localStorage.removeItem("accessToken");
              window.location.reload();
            }}
          >
            ออกจากระบบ
          </Text>
        </div>
      </div>
    </Container>
  );
};

const SidebarItem = ({
  name,
  icon,
  path,
  pages,
  currentPath,
  isLastPage,
  minimize,
  setMinimize,
  onClick,
}) => {
  const navigate = useNavigate();
  const { isDarkTheme } = Context();

  const isContainCurrentPage = pages?.some((path) => path.path === currentPath);
  const [isShow, setIsShow] = useState(isContainCurrentPage);

  return (
    <>
      <div
        className={`flex ${
          minimize ? "justify-center" : "justify-between"
        } items-center py-3 rounded-[20px] cursor-pointer duration-150 ${
          isDarkTheme
            ? `${
                currentPath === path
                  ? "text-[rgb(8,19,37)] bg-white font-bold"
                  : "text-gray-400 hover:text-white"
              } 
               ${
                 minimize
                   ? `px-0 ${
                       isContainCurrentPage &&
                       "text-gray-900 bg-white font-bold shadow-xl"
                     }`
                   : `px-4 ${isShow && "font-bold text-white"}`
               }`
            : `${
                currentPath === path
                  ? "text-white bg-[rgb(8,19,37)] font-bold shadow-xl"
                  : "text-gray-500 hover:text-[rgb(8,19,37)]"
              }
              ${
                minimize
                  ? `px-0 ${
                      isContainCurrentPage &&
                      "text-white bg-[rgb(8,19,37)] font-bold shadow-xl"
                    }`
                  : ` ${isShow && "font-bold text-[rgb(8,19,37)]"} ${
                      isContainCurrentPage && "font-bold"
                    } px-4`
              }    
              `
        }`}
        onClick={() => {
          if (path) {
            navigate(path);
          } else if (minimize) {
            setIsShow(true);
            setMinimize(!minimize);
          } else {
            setIsShow(!isShow);
          }
        }}
      >
        <div className={`flex gap-3`}>
          {icon} {!minimize && name}
        </div>
        {!minimize && pages?.length > 0 && (
          <IoMdArrowDropdown
            className={`${isShow ? "" : "rotate-90"} duration-150`}
          />
        )}
      </div>
      {!minimize && pages?.length > 0 && (
        <Collapse isOpen={isShow}>
          {pages.map((page, index) => {
            return (
              <Text
                className={`pl-16 py-3 my-1 rounded-[20px] cursor-pointer duration-150 text-sm font-medium ${
                  isDarkTheme
                    ? `${
                        currentPath === page.path
                          ? "text-gray-950 bg-white font-bold shadow-xl"
                          : "text-gray-400 hover:text-white "
                      }`
                    : `${
                        page.path === currentPath
                          ? "bg-gray-950 text-white font-bold shadow-xl"
                          : "text-gray-400 hover:text-gray-950"
                      }`
                } `}
                key={page.path}
                onClick={() => navigate(page.path)}
              >
                {page.name}
              </Text>
            );
          })}
          <Separator className={`${isLastPage ? "hidden" : "my-3"} `} />
        </Collapse>
      )}
    </>
  );
};
